import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import formatRoomName from '../../helpers/formatRoomName/formatRoomName';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import useRateContent from '../../hooks/useRateContent/useRateContent';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { RoomRateDetails } from '../../views/SelectRoom/components/RoomRate/components';
import BookingButton from '../BookingButton/BookingButton';
import TaxesAndFees from '../BookingsCart/TaxesAndFees/TaxesAndFees';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import NightlyRates from '../NightlyRates/NightlyRates';
import Policies from '../Policies/Policies';
import styles from './RoomRateModal.module.css';

const RoomRateModal = ({
  open,
  roomTypeTitle,
  categoryName,
  productCode,
  priceBeforeTax,
  showBookBtn,
  numBookingNights,
  pricesPerNight,
  onBookNowBtnClick,
  selectRoomForm = null,
  booking = null,
  onClose,
  rate,
  roomTypeCode,
}) => {
  const moment = useLocalisedMoment();
  const { t } = useTranslate();
  const {
    name: rateName,
    price,
    code,
    shortDescription,
    longDescription,
    currencyCode,
    serviceCharge,
  } = rate;

  const { printPrice } = usePrintPrice(productCode);

  const rateContentParams = selectRoomForm?.watch() || booking;

  const { rateContent, loading } = useRateContent(
    code,
    productCode,
    rateContentParams
  );

  const taxes = rateContent.roomsPricing?.find(
    (roomPricing) => roomPricing.roomTypeCode === roomTypeCode
  )?.taxes;

  const numRooms = selectRoomForm?.watch('guestsPerRoom').length || 1;
  const agentCrmId = selectRoomForm?.watch('agentCrmId');

  const hasAgentProfile = useSelector((state) => !!state.bookings.agentProfile);
  const currency = useSelector((state) => state.appSettings.currencies.current);

  const { price: printedPrice } = printPrice({
    value: price,
    valueExcludingTaxesAndFees: priceBeforeTax,
    valueIncludingServiceCharge: priceBeforeTax + serviceCharge,
    baseCurrency: currencyCode,
  });

  return (
    <Modal
      isOpen={open}
      external={
        <ModalCloseButton
          onClose={onClose}
          className={styles.RoomRateModal__closeButton}
        />
      }
      contentClassName={styles.RoomRateModal__modalWrapper}
      toggle={onClose}
      aria-labelledby={`${roomTypeTitle}ModalID`}
      scrollable
    >
      <ModalBody>
        <div className={styles.RoomRateModal__modalBody}>
          <Row>
            <Col md="8" className="mb-4">
              <Row>
                <div>
                  <h1>{formatRoomName(categoryName, roomTypeTitle)}</h1>

                  <h2 className="p-0 mt-2" id={`${roomTypeTitle}ModalID`}>
                    {rateName}
                  </h2>

                  {loading && (
                    <div className="mb-1">
                      <Skeleton height={20} width={200} className="mb-2" />
                      <Skeleton height={20} width={300} />
                    </div>
                  )}

                  {!loading && (
                    <div className="mt-3">
                      <RoomRateDetails
                        name={rateName}
                        shortDescription={shortDescription}
                        longDescription={longDescription}
                        rateContent={rateContent}
                      />
                    </div>
                  )}
                </div>
              </Row>

              <Row>
                <h2 className="p-0 mb-4 mx-2">{t('Policies')}</h2>
                <Policies
                  policies={rateContent.policyList?.[roomTypeCode]}
                  loading={loading}
                  showCommission={hasAgentProfile && agentCrmId}
                />
              </Row>
            </Col>
            <Col md="4 p-0">
              <div className={styles.RoomRateModal__rightBody}>
                <div className={styles.RoomRateModal__tableRows}>
                  <div>
                    <div className={styles.RoomRateModal__priceRow}>
                      <span>
                        {numBookingNights > 1
                          ? `${numBookingNights} ${t('nights')}`
                          : moment(pricesPerNight?.[0].date).format(
                              'ddd, MMM D YYYY'
                            )}{' '}
                      </span>
                      <span className="bold">{printedPrice}</span>
                    </div>
                    {numBookingNights > 1 && (
                      <div className={styles.RoomRateModal__nightlyRates}>
                        <NightlyRates
                          title={t('Nightly Rates')}
                          pricesPerNight={pricesPerNight}
                          productCode={productCode}
                          currencyCode={currencyCode}
                        />
                      </div>
                    )}
                  </div>
                  {loading ? (
                    <Skeleton
                      height={50}
                      className="my-4"
                      containerTestId="rate-skeleton"
                    />
                  ) : taxes?.total ? (
                    <div>
                      <TaxesAndFees
                        taxes={taxes}
                        productCode={productCode}
                        currencyCode={currencyCode}
                      />
                    </div>
                  ) : null}
                </div>

                <div className={styles.RoomRateModal__totalPrice}>
                  <span className="text-uppercase">{t('Total')}</span>
                  <span>
                    {
                      printPrice({
                        value: price,
                        baseCurrency: currencyCode,
                      }).price
                    }
                  </span>
                </div>
                <div className={styles.RoomRateModal__taxGBP}>
                  ({currency} {t('tax included')})
                </div>
                <div className={styles.RoomRateModal__buttonRow}>
                  {showBookBtn && (
                    <BookingButton
                      rateCode={code}
                      rateName={rateName}
                      roomTypeTitle={roomTypeTitle}
                      onBook={onBookNowBtnClick}
                      roomTypeCode={productCode}
                      numRooms={numRooms}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};
RoomRateModal.propTypes = {
  open: PropTypes.bool,
  roomTypeTitle: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  productCode: PropTypes.string,
  code: PropTypes.string,
  price: PropTypes.number,
  pricesPerNight: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      price: PropTypes.number,
    })
  ),
  booking: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    numAdults: PropTypes.number,
    numRooms: PropTypes.number,
  }),
  selectRoomForm: PropTypes.object,
  numBookingNights: PropTypes.number,
  showBookBtn: PropTypes.bool,
  onBookNowBtnClick: PropTypes.func,
  onClose: PropTypes.func,
  priceBeforeTax: PropTypes.number,
  rate: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    shortDescription: PropTypes.string,
    longDescription: PropTypes.string,
    currencyCode: PropTypes.string,
    serviceCharge: PropTypes.number.isRequired,
  }),
  roomTypeCode: PropTypes.string,
};

export default RoomRateModal;

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DY_IDS } from '../../constants';
import { CHINA_VERSION, env } from '../../globals';

const DY_ID = DY_IDS[env];

const cookieLawLinks = () => [
  <script
    key="cookies"
    name="cookies"
    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    data-document-language="true"
    type="text/javascript"
    data-domain-script="fbe959fa-e76b-4b24-ad6b-acdaa0b39226-test"
  />,
  <script name="cookiesModal" type="text/javascript" key="cookies-modal">
    {`function OptanonWrapper() {}`}
  </script>,
];

const dynamicYieldLinks = () => [
  <link
    key="preconnect-cdn"
    rel="preconnect"
    href="//cdn-eu.dynamicyield.com"
  />,
  <link key="preconnect-st" rel="preconnect" href="//st-eu.dynamicyield.com" />,
  <link
    key="preconnect-rcom"
    rel="preconnect"
    href="//rcom-eu.dynamicyield.com"
  />,
  <link
    key="dns-prefetch-cdn"
    rel="dns-prefetch"
    href="//cdn-eu.dynamicyield.com"
  />,
  <link
    key="dns-prefetch-st"
    rel="dns-prefetch"
    href="//st-eu.dynamicyield.com"
  />,
  <link
    key="dns-prefetch-rcom"
    rel="dns-prefetch"
    href="//rcom-eu.dynamicyield.com"
  />,
  <script
    key="script-dynamic"
    type="text/javascript"
    src={`//cdn-eu.dynamicyield.com/api/${DY_ID}/api_dynamic.js`}
  />,
  <script
    key="script-static"
    type="text/javascript"
    src={`//cdn-eu.dynamicyield.com/api/${DY_ID}/api_static.js`}
  />,
];

const gtmLinks = () => [
  <script key="data-layer">{`window.dataLayer = window.dataLayer || [];`}</script>,
  <script key="gtm">
    {`(function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KKXX6KK');`}
  </script>,
  <noscript key="gtm-no-script">
    {`
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KKXX6KK"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `}
  </noscript>,
];

const constantScripts = () => [
  <script
    key="googleMaps"
    async
    name="googleMaps"
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA1HNDGohfGNdSSQlf2E3jCVBZTJUKZ7VY&loading=async&libraries=places&callback=Function.prototype"
  />,
  <script
    key="geoIp"
    name="geoIp"
    src="//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js"
    type="text/javascript"
  />,
  <script
    key="onelink"
    name="onelink"
    referrerPolicy="no-referrer-when-downgrade"
    type="text/javascript"
    src="https://www.onelink-edge.com/moxie.min.js"
    data-oljs="P8B59-98A1-1A94-9836"
  />,
];

const ScriptLoader = ({ onScriptsLoad }) => {
  const handleHelmetChangeClientState = ({ scriptTags }) => {
    if (scriptTags?.length) {
      const scriptsToLoad = scriptTags.filter((scriptEl) => scriptEl.src);
      let scriptElementsLoadedCount = 0;
      scriptsToLoad.forEach((scriptElement) => {
        scriptElement.onload = () => {
          scriptElementsLoadedCount++;
          if (scriptElementsLoadedCount === scriptsToLoad.length) {
            onScriptsLoad();
          }
        };
      });
    }
  };

  // if china, call onScriptsLoad immediately
  useEffect(() => {
    if (CHINA_VERSION) {
      onScriptsLoad();
    }
  }, [onScriptsLoad]);

  if (CHINA_VERSION) {
    return null;
  }

  return (
    <div>
      <Helmet
        onChangeClientState={(_, addedTags) =>
          handleHelmetChangeClientState(addedTags)
        }
      >
        {env !== 'local' ? cookieLawLinks() : null}
        {env !== 'local' ? dynamicYieldLinks() : null}
        {env !== 'local' ? gtmLinks() : null}
        {constantScripts()}
      </Helmet>
    </div>
  );
};

ScriptLoader.propTypes = {
  onScriptsLoad: PropTypes.func,
};

export default ScriptLoader;
